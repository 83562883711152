import React, { ReactNode } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material'

interface IButtonTextApp {
	onClick?: any
	children: React.ReactNode
	color?: 'primary' | 'secondary' | 'inherit'
	startIcon?: any
	size?: 'small' | 'medium' | 'large' | undefined
	sx?: any
}

export const ButtonTextApp = ({
	onClick,
	children,
	color,
	startIcon,
	size,
	sx = {},
}: IButtonTextApp) => {
	return (
		<Button
			onClick={onClick}
			color={color}
			variant="text"
			size={size}
			sx={{
				fontWeight: '400',
				fontSize: '16px',
				textTransform: 'inherit',
				'&:hover': {
					color: 'rgb(197, 157, 95)',
				},
				...sx,
			}}
			startIcon={startIcon}
		>
			{children}
		</Button>
	)
}

interface IButtonApp extends ButtonProps {
	color?: 'primary' | 'secondary' | 'inherit'
	outlined?: boolean
	disabled?: boolean
	submit?: boolean
	onClick?: () => void
	notFullWidth?: boolean
	children: ReactNode
	startIcon?: any
	width?: string
	sx?: SxProps<Theme>
}

export const ButtonApp = ({
	color = 'primary',
	outlined,
	disabled,
	submit,
	onClick,
	notFullWidth,
	children,
	startIcon = null,
	width,
	sx = {},
}: IButtonApp) => {
	return (
		<Button
			color={color}
			size="medium"
			variant={outlined ? 'outlined' : 'contained'}
			disabled={disabled}
			type={submit ? 'submit' : 'button'}
			onClick={onClick}
			fullWidth={!notFullWidth}
			sx={{
				paddingX: `${notFullWidth ? '35px' : 'auto'}`,
				textTransform: 'none',
				width: `${width ? width : '100%'}`,
				border: outlined ? '1px solid ' : 0,
				borderRadius: '6px',
				height: '40px',
				lineHeight: '20px',
				fontSize: '14px',
				fontWeight: 500,
				'&:hover': {
					background: outlined ? '#F0EAD8' : '#CCBA7C',
					color: outlined ? '#CCBA7C' : '#FFF',
					border: outlined ? '1px solid #787579' : 0,
					boxShadow: outlined
						? 'none'
						: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
				},
				...sx,
			}}
			startIcon={startIcon}
		>
			{children}
		</Button>
	)
}
