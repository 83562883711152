import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const PrivateReferenceRoutes = [
	{
		nameUrl: 'Referencia',
		path: urlRoutes.payment.privateReference.url,
		element: lazy(() => import('../../../pages/privateReference')),
		rols: ['Admin', 'User', '*'],
	},
]
