import { combineReducers, configureStore } from '@reduxjs/toolkit'

import loggedUserReducer from './slices/loggedUserSlice'
import userReducer, { logout } from './slices/userSlice'
import identificationTypeReducer from './slices/identificationTypeSlice'
import regionReducer from './slices/regionSlice'
import cityReducer from './slices/citySlice'
import subscriptionsReducer from './slices/subscriptionsSlice'
import couponReducer from './slices/couponSlice'
import memberUserReducer from './slices/memberUserSlice'
import paymentOrderReducer from './slices/paymentOrderSlice'
import checkoutReducer from './slices/checkoutSlice'
import countryReducer from './slices/countrySlice'

const appReducer = combineReducers({
	user: userReducer,
	loggedUser: loggedUserReducer,
	identificationType: identificationTypeReducer,
	city: cityReducer,
	region: regionReducer,
	subscriptions: subscriptionsReducer,
	coupon: couponReducer,
	memberUser: memberUserReducer,
	paymentOrder: paymentOrderReducer,
	checkout: checkoutReducer,
	country: countryReducer,
})

const rootReducer = (state: any, action: any) => {
	if (action.type === logout.type) {
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default configureStore({
	reducer: rootReducer,
})
