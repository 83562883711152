import React, { Suspense } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Spinner from '../components/elements/spinner'
import Layout from '../components/layout'
import PrivatedRoutes from './privatedRoutes'
import PublicPages from './publicPages/publicPages'
import PrivatePages from './privatedPages/privatePages'
import { urlRoutes } from './config/urlRoutes/urlRoutes'
import { getLoggedUser } from '../redux/slices/loggedUserSlice'
import PublicRoutes from './publicRoutes'

export default function Index() {
	const userManager = useSelector(getLoggedUser)

	return (
		<Router>
			<Layout>
				<Suspense fallback={<Spinner />}>
					<Routes>
						<Route element={<PublicRoutes isLoggedIn={userManager?.logged} />}>
							<Route path={urlRoutes.auth.url} element={<PublicPages />} />
						</Route>
						<Route
							element={<PrivatedRoutes isLoggedIn={userManager?.logged} />}
						>
							<Route path={'/*'} element={<PrivatePages rol="Admin" />} />

						</Route>
						<Route path={urlRoutes.checkout.url} element={<PublicPages />} />
						<Route path={urlRoutes.payment.url} element={<PublicPages />} />
					</Routes>
				</Suspense>
			</Layout>
		</Router>
	)
}
