import { createSlice } from '@reduxjs/toolkit'

export const paymentOrderSlice = createSlice({
	name: 'paymentOrder',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0,
		},
	},
	reducers: {
		setPaymentOrder: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getPaymentOrders = (state: any) => state.paymentOrder

export const { setPaymentOrder } = paymentOrderSlice.actions

export default paymentOrderSlice.reducer
