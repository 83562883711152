import React, { ReactNode, useState } from 'react'
import {
	CircleOutlined as CircleOutlinedIcon,
	ExpandLess,
	ExpandMore,
	Logout as LogoutIcon,
	Menu as MenuIcon,
} from '@mui/icons-material'
import {
	AppBar,
	Box,
	Collapse,
	CssBaseline,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import './style.css'
import Logo from '../../assets/logo.png'
import LogoMobile from '../../assets/logoSorvo.svg'
import AccountMenu from './accountMenu'
import { drawerList } from './drawer'
import Footer from './footer'
import { ButtonTextApp } from '../elements/button'
import {
	getLoggedUser,
	setLoggedUser,
} from '../../redux/slices/loggedUserSlice'
import UserIcon from '../../assets/user.svg'
import { urlRoutes } from '../../routes/config/urlRoutes/urlRoutes'
import { MemberUserController } from '../../../infraestructure/controllers/memberUserController'

const drawerWidth = '240px'

interface Props {
	window?: () => Window
	children: ReactNode
}

export default function LayoutManagement(props: Props) {
	const userManager = useSelector(getLoggedUser)
	const dispatch = useDispatch()
	const location = useLocation()
	const theme = useTheme()
	const routeSplit = location.pathname.split('/')
	const isInAddressPage = Number(routeSplit[routeSplit.length - 1]) === 1
	const isMedium = useMediaQuery(theme.breakpoints.up('sm'))
	const { window: windowMui, children } = props
	const [mobileOpen, setMobileOpen] = useState(false)
	const [mobileOpenNav, setMobileOpenNav] = useState(false)
	const [open, setOpen] = useState(-1)
	const navigate = useNavigate()

	const handleClick = (index: any) => () => {
		if (index === open) {
			setOpen(-1)
		} else {
			setOpen(index)
		}
	}

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen)
		setMobileOpenNav(false)
	}

	const handleDrawerToggleNav = () => {
		setMobileOpenNav(!mobileOpenNav)
		setMobileOpen(false)
	}

	const navItems = [
		{ label: 'Conócenos', url: process.env.REACT_APP_URL_CONOCENOS },
		{ label: 'Membresías', url: process.env.REACT_APP_URL_MEMBRESIAS },
		{
			label: 'Nuestra cava',
			url: process.env.REACT_APP_URL_MARKETPLACE,
		},
	]

	const handleLogout = () => {
		MemberUserController.logout(userManager.refreshToken).catch(() => {
			console.log('Error al cerrar la sesión')
		})
		localStorage.clear()
		navigate(urlRoutes.auth.login.urlTo)
		dispatch(setLoggedUser({}))
	}

	const drawer = (
		<Box sx={{ marginTop: { xs: '12px', md: '5px' } }}>
			<List>
				<>
					{drawerList.map((data: any, index: number) => (
						<Box key={index}>
							{data.module && <Divider />}
							{data.items.map((dataItems: any, index: number) =>
								!dataItems.children[0] ? (
									<NavLink
										key={index}
										to={dataItems.link}
										className={({ isActive }) =>
											isActive ? 'activeStyle' : 'notActiveStyle'
										}
									>
										<ListItemButton>
											<ListItemIcon>{dataItems.icon}</ListItemIcon>
											<ListItemText
												style={{ padding: '5px 0' }}
												primary={dataItems.text}
											/>
										</ListItemButton>
									</NavLink>
								) : (
									<Box key={index}>
										<ListItemButton
											style={{
												background: open === dataItems.itemId ? '#003876' : '',
												color: open === dataItems.itemId ? '#fff' : '#003876',
											}}
											onClick={handleClick(dataItems.itemId)}
										>
											<ListItemIcon
												style={{
													background:
														open === dataItems.itemId ? '#003876' : '',
													color: open === dataItems.itemId ? '#fff' : '#003876',
												}}
												sx={{ minWidth: '40px' }}
											>
												{dataItems.icon}
											</ListItemIcon>
											<ListItemText primary={dataItems.text} />
											{open === dataItems.itemId ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemButton>

										<Collapse
											in={open === dataItems.itemId}
											timeout="auto"
											unmountOnExit
											sx={{ background: '#e8f4ff' }}
										>
											<List component="div" disablePadding>
												{dataItems.children.map(
													(dataChildren: any, index: number) => (
														<NavLink
															key={index}
															to={dataChildren.link}
															className={({ isActive }) =>
																isActive ? 'activeStyle' : 'notActiveStyle'
															}
														>
															<ListItemButton sx={{ pl: 4 }}>
																<ListItemIcon sx={{ minWidth: '20px' }}>
																	<CircleOutlinedIcon
																		style={{ fontSize: '12px' }}
																	/>
																</ListItemIcon>
																<ListItemText primary={dataChildren.text} />
															</ListItemButton>
														</NavLink>
													)
												)}
											</List>
										</Collapse>
									</Box>
								)
							)}
						</Box>
					))}
					<Box
						sx={{
							color: '#FFFFFF',
							padding: '8px 16px',
							cursor: 'pointer',
							display: { xs: 'block', sm: 'none' },
						}}
						onClick={handleLogout}
					>
						<ListItem disablePadding>
							<ListItemIcon sx={{ minWidth: '40px' }}>
								<LogoutIcon sx={{ color: '#FFFFFF' }} />
							</ListItemIcon>
							<ListItemButton>
								<ListItemText primary={'Cerrar sesión'} />
							</ListItemButton>
						</ListItem>
					</Box>
				</>
			</List>
		</Box>
	)

	const drawerNav = (
		<Box
			onClick={handleDrawerToggleNav}
			sx={{ textAlign: 'center', marginTop: '35px' }}
		>
			<Toolbar />
			<List>
				{navItems.map((item, index) => (
					<ListItem
						onClick={() => window.open(item.url)}
						key={index}
						disablePadding
						sx={{ paddingY: '8px' }}
					>
						<ListItemButton>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
				{!userManager?.logged && (
					<ListItem
						onClick={() => navigate(urlRoutes.auth.login.urlTo)}
						disablePadding
						sx={{ marginTop: '8px' }}
					>
						<ListItemButton>
							<ListItemText primary="Acceso a socios" />
						</ListItemButton>
					</ListItem>
				)}
			</List>
		</Box>
	)

	const container =
		windowMui !== undefined ? () => windowMui().document.body : undefined

	return (
		<Box
			sx={{
				display: { xs: 'block', md: 'flex' },
				flexDirection: { md: 'column' },
				height: '100vh',
				width: '100%',
			}}
		>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
					background: 'rgba(18, 18, 18, 1)',
					height: { xs: '70px', md: '90px' },
				}}
			>
				<Toolbar
					sx={{ height: '100%', padding: { xs: '0 16px', md: '0 80px' } }}
				>
					<Box
						sx={{
							display: { xs: 'flex', sm: 'none' },
							justifyContent: 'space-between',
							width: '100%',
							alignItems: 'center',
						}}
					>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggleNav}
						>
							<MenuIcon />
						</IconButton>
						<Box
							sx={{
								height: '50px',
								width: '100px',
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
							}}
							onClick={() => window.open(process.env.REACT_APP_URL_HOME)}
						>
							<img
								alt="LogoMobile"
								src={LogoMobile}
								style={{ height: '100%', width: '100%' }}
							/>
						</Box>
						{userManager?.logged ? (
							<IconButton
								color="inherit"
								aria-label="open drawer nav"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 1, display: { sm: 'none' } }}
							>
								<img src={UserIcon} alt={UserIcon} />
							</IconButton>
						) : (
							<div />
						)}
					</Box>
					<Box
						sx={{
							display: { xs: 'none', sm: 'flex' },
							cursor: 'pointer',
							maxWidth: '163px',
							width: '100%',
							marginRight: 'auto',
							height: '87px',
							alignItems: 'center',
						}}
						onClick={() => window.open(process.env.REACT_APP_URL_HOME)}
					>
						<img
							alt="Logo"
							src={Logo}
							style={{ width: '100%', height: '100%' }}
						/>
					</Box>
					<Box
						sx={{
							display: { xs: 'none', sm: 'flex' },
							flexDirection: { sm: 'row' },
						}}
					>
						{navItems.map((item: any, index) => (
							<Box
								key={index}
								sx={{ display: 'inline-flex', alignItems: 'center' }}
							>
								<ButtonTextApp
									onClick={() => window.open(item.url)}
									color="inherit"
								>
									{item.label}
								</ButtonTextApp>
								<Box
									sx={{
										display: 'inline-block',
										borderRight: '1px solid white',
										marginRight: '5px',
										width: '1px',
										height: '25px',
										paddingRight: '5px',
										position: 'relative',
									}}
								/>
							</Box>
						))}
						<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
							{userManager?.logged ? (
								<AccountMenu handleLogout={handleLogout} />
							) : (
								<ButtonTextApp
									startIcon={
										<Box sx={{ padding: '10px 8px 0 8px' }}>
											<img src={UserIcon} alt={UserIcon} />{' '}
										</Box>
									}
									onClick={() => navigate(urlRoutes.auth.login.urlTo)}
									color="inherit"
								>
									Acceso a socios
								</ButtonTextApp>
							)}
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{
					width: { sm: userManager?.logged ? drawerWidth : 0 },
					flexShrink: { sm: 0 },
				}}
			>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpenNav}
					onClose={handleDrawerToggleNav}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
							background: '#333333',
							color: '#FFFFFF',
						},
					}}
				>
					{drawerNav}
				</Drawer>
			</Box>
			{userManager?.logged && (
				<>
					<Drawer
						container={container}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						anchor="right"
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						sx={{
							display: { xs: 'block', sm: 'none' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: drawerWidth,
								background: 'rgb(39, 38, 38)',
							},
						}}
					>
						<Toolbar />
						<Box sx={{ overflow: 'auto' }}>{drawer}</Box>
					</Drawer>
					<Drawer
						variant="permanent"
						sx={{
							display: { xs: 'none', sm: 'block' },
							'& .MuiDrawer-paper': {
								boxSizing: 'border-box',
								width: drawerWidth,
								zIndex:
									userManager.logged && isMedium && isInAddressPage ? 0 : 1200,
							},
						}}
						open
					>
						<Toolbar />
						<Box sx={{ overflow: 'auto' }}>{drawer}</Box>
					</Drawer>
				</>
			)}

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					pt: 8,
					width: '100%',
					paddingLeft: {
						sm: userManager?.logged && drawerWidth,
					},
				}}
			>
				{children}
			</Box>
			<Footer isMedium={isMedium} isInAddressPage={isInAddressPage} />
		</Box>
	)
}
