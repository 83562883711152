import React, { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { redirect } from 'react-router'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'

import Routes from './presentation/routes'
import { theme } from './presentation/themes'
import {
	getLoggedUser,
	setLoggedUser,
} from './presentation/redux/slices/loggedUserSlice'
import { urlRoutes } from './presentation/routes/config/urlRoutes/urlRoutes'
import { MemberUserController } from './infraestructure/controllers/memberUserController'
import TagManager from 'react-gtm-module'
import { formatLocalDate } from './presentation/utils/format'

if (
	process.env.REACT_APP_ENVIRONMENT === 'test' ||
	process.env.REACT_APP_ENVIRONMENT === 'production'
) {
	const tagManagerArgs = {
		gtmId: 'GTM-PQ4NL3X',
	}
	TagManager.initialize(tagManagerArgs)
}

function App() {
	const dispatch = useDispatch()

	const { logged, accessExpiresIn, refreshExpiresIn, refreshToken } =
		useSelector(getLoggedUser)

	const handleLogout = () => {
		localStorage.clear()
		dispatch(setLoggedUser({}))
		return redirect(urlRoutes.auth.login.urlTo)
	}

	const handleRequestNewToken = () => {
		MemberUserController.refreshToken(refreshToken)
			.then((res: any) => {
				const responseLoggedUser = {
					logged: true,
					loggedUser: jwt_decode(res.data.accessToken),
					...res.data,
				}
				localStorage.setItem('loggedUser', JSON.stringify(responseLoggedUser))
				dispatch(setLoggedUser(responseLoggedUser))
			})
			.catch(() => handleLogout())
	}

	useEffect(() => {
		let accessTimer: NodeJS.Timer
		let refreshTimer: NodeJS.Timer
		if (logged) {
			const accessTokenInterval =
				formatLocalDate(accessExpiresIn).getTime() -
				new Date().getTime() -
				12000

			const refreshTokenInterval =
				formatLocalDate(refreshExpiresIn).getTime() - new Date().getTime()

			accessTimer = setInterval(handleRequestNewToken, accessTokenInterval)
			refreshTimer = setTimeout(handleLogout, refreshTokenInterval)
		}
		return () => {
			clearInterval(accessTimer)
			clearTimeout(refreshTimer)
		}
	}, [logged])

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Routes />
		</ThemeProvider>
	)
}

export default App
