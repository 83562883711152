import moment from 'moment'
import 'moment/locale/es'

moment().locale('es')

//Date-Time
export const formatLocalDate = (data: string | Date) =>
	moment.utc(data).local().toDate()

//From UTC to Local
export const formatLocalDateString = (data: string | Date) =>
	moment.utc(data).local().format('DD/MM/YYYY')

export const formatLocalDateNewString = (data: string | Date) =>
	moment.utc(data).local().format('MMMM DD, YYYY')

export const formatLocalDateTimeString = (data: string | Date) =>
	moment.utc(data).local().format('DD/MM/YYYY HH:mm:ss')

export const formatUtcDate = (data: string | Date, format?: string) => {
	if (String(data).split('/').length === 3) {
		return moment(data, format || 'DD/MM/YYYY')
			.utc()
			.toDate()
	}
	return moment(data).utc().toDate()
}

export const validateOnlyNumber = (valor: string) => {
	const regExp = /^\d*$/
	return regExp.test(valor)
}

export const validateOnlyLettersAndNumbers = (value: string) => {
	const regExp = /^[\da-zA-Z]*$/
	return regExp.test(value)
}

export const validateCellPhone = (valor: string) => {
	const regExp = /^[+]?\d*$/
	return regExp.test(valor)
}
