import { createSlice } from '@reduxjs/toolkit'

export const memberUserSlice = createSlice({
	name: 'memberUser',
	initialState: {
		entities: [],
		pagination: {
			page: 0,
			pageSize: 0,
			total: 0
		},
	},
	reducers: {
		setMemberUser: (state, action) => {
			state.entities = action.payload.entities
			state.pagination = action.payload.pagination
		},
	},
})

export const getMemberUser = (state: any) => state.memberUser

export const { setMemberUser } = memberUserSlice.actions

export default memberUserSlice.reducer
