import IMemberUserRepository from '../../domain/interfaces/repositories/IMemberUserRepository'
import {
	AuthLoginUserRequest,
	AuthLoginUserResponse,
	AuthLogoutUserResponse, AuthOtpCodeUserRequest, AuthOtpCodeUserResponse,
	MemberUser,
	ReadMemberUserValidateSubscriptionByEmailQueryResponse,
	ResetPasswordCommandRequest, SetClassificationMemberUserCommandRequest, SetClassificationMemberUserCommandResponse,
	UpdateMemberUserCommandRequest,
	UpdateMemberUserCommandResponse
} from '../../domain/entities/memberUser'
import { sendRequest } from '../dataSources/http/axios'
import { AsyncApiResponse } from '../../domain/models/apiResponse'

export default class MemberUserRepository implements IMemberUserRepository {
	async getById(id: string): AsyncApiResponse<MemberUser> {
		const request = {
			get: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/${id}`,
			},
		}
		return await sendRequest<MemberUser>(request)
	}

	async update(
		data: UpdateMemberUserCommandRequest
	): AsyncApiResponse<UpdateMemberUserCommandResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/update`,
				body: data,
			},
		}
		return await sendRequest<UpdateMemberUserCommandResponse>(request)
	}

	async otpCode(
		data: AuthOtpCodeUserRequest
	): AsyncApiResponse<AuthOtpCodeUserResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/auth/code`,
				body: data,
			},
		}
		return await sendRequest<AuthOtpCodeUserResponse>(request)
	}

	async login(
		data: AuthLoginUserRequest
	): AsyncApiResponse<AuthLoginUserResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/auth/login`,
				body: data,
			},
		}
		return await sendRequest<AuthLoginUserResponse>(request)
	}

	async logout(refreshToken: string): AsyncApiResponse<AuthLogoutUserResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/auth/logout`,
				body: { refreshToken: refreshToken },
			},
		}
		return await sendRequest<AuthLogoutUserResponse>(request)
	}
	async refreshToken(
		refreshToken: string
	): AsyncApiResponse<AuthLoginUserResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/refresh-token`,
				body: { refreshToken: refreshToken },
			},
		}
		return await sendRequest<AuthLoginUserResponse>(request)
	}

	async recover(email: string): AsyncApiResponse<any> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/auth/forgot-password`,
				body: { email: email },
			},
		}
		return await sendRequest<any>(request)
	}

	async reset(data: ResetPasswordCommandRequest): AsyncApiResponse<any> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/auth/reset-password`,
				body: data,
			},
		}
		return await sendRequest<any>(request)
	}

	async validateSubscriptionByEmail(
		email: string
	): AsyncApiResponse<ReadMemberUserValidateSubscriptionByEmailQueryResponse> {
		const request = {
			get: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/valid-email/${email}`,
			},
		}
		return await sendRequest<ReadMemberUserValidateSubscriptionByEmailQueryResponse>(
			request
		)
	}

	async setClassification(
		data: SetClassificationMemberUserCommandRequest
	): AsyncApiResponse<SetClassificationMemberUserCommandResponse> {
		const request = {
			post: {
				path: `${process.env.REACT_APP_URL_BASE}/users/members/set-classification`,
				body: data,
			},
		}
		return await sendRequest<SetClassificationMemberUserCommandResponse>(request)
	}
}
