import { createSlice } from '@reduxjs/toolkit'

export const checkoutSlice = createSlice({
	name: 'checkout',
	initialState: {
		createCheckoutResponse: undefined,
		paymentPlaceToPayResponse: undefined,
		isChangedPaymentMethod: false,
		searchParamsUrlArray: [],
		paymentFlow: null,
	},
	reducers: {
		setCheckoutResponse: (state, action) => {
			state.createCheckoutResponse = action.payload
		},
		setIsChangedPaymentMethod: (state, action) => {
			state.isChangedPaymentMethod = action.payload
		},
		setSearchParamsUrlArray: (state, action) => {
			state.searchParamsUrlArray = action.payload
		},
	},
})

export const getCheckoutData = (state: any) => state.checkout

export const {
	setCheckoutResponse,
	setIsChangedPaymentMethod,
	setSearchParamsUrlArray,
} = checkoutSlice.actions

export default checkoutSlice.reducer
