import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const CheckoutRoutes = [
	{
		path: urlRoutes.checkout.checkout.url,
		element: lazy(() => import('../../../pages/checkout')),
	},
]
