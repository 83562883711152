import React from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined'
import { urlRoutes } from '../../routes/config/urlRoutes/urlRoutes'

export const drawerList = [
	{
		module: null,
		items: [
			{
				icon: <WorkspacePremiumOutlinedIcon />,
				text: 'Mi membresía',
				link: urlRoutes.home.url,
				children: [],
			},
		],
	},
	{
		module: null,
		items: [
			{
				icon: <AccountCircleOutlinedIcon />,
				text: 'Mi perfil',
				link: urlRoutes.profile.url,
				children: [],
			},
		],
	},
]
