export const urlRoutes = {
	auth: {
		url: '/autenticacion/*',
		login: {
			url: '/login',
			urlTo: '/autenticacion/login',
		},
		recover: {
			url: '/recover',
			urlTo: '/autenticacion/recover',
		},
		reset: {
			url: '/cambiar-contrasena',
			urlTo: '/autenticacion/cambiar-contrasena',
		},
		otpCode: {
			url: '/otp-code',
			urlTo: '/autenticacion/otp-code',
		},
	},
	checkout: {
		url: '/membresia/*',
		checkout: {
			url: '/checkout/:id/:idTab',
			urlTo: '/membresia/checkout',
		},
	},
	payment: {
		url: '/reference/*',
		reference: {
			url: '/:paymentReference/:memberUserId',
			urlTo: '/reference',
		},
		privateReference: {
			url: '/private-reference/:paymentReference/:memberUserId',
			urlTo: '/private-reference',
		},
	},
	home: {
		url: '/',
		cancelMembership: {
			url: '/membresia-cancelada',
		},
	},
	profile: {
		url: '/perfil',
	},
}
