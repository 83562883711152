import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const ReferenceRoutes = [
	{
		path: urlRoutes.payment.reference.url,
		element: lazy(() => import('../../../pages/reference')),
	},
]
