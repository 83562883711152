import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const TextTitle = styled(Typography)(() => ({
	fontWeight: 700,
	fontSize: '16px',
}))

export const TextSubTitle = styled(Typography)(() => ({
	fontWeight: 400,
	fontSize: '14px',
	cursor: 'pointer',
}))
