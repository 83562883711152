import React from 'react'
import Grid from '@mui/material/Grid'

interface IPropsContainer {
	children: React.ReactNode
	spacing?: number
	marginY?: boolean
	justifyCenter?: boolean
	columnGap?: string
	rowGap?: string
}

export const GridContainer = ({
	children,
	marginY,
	justifyCenter,
	columnGap,
	rowGap,
	spacing = 2,
}: IPropsContainer) => (
	<Grid
		container
		spacing={spacing}
		direction="row"
		justifyContent={justifyCenter ? 'center' : 'flex-start'}
		marginY={marginY ? 1 : 'none'}
		columnGap={columnGap ?? 0}
		rowGap={rowGap ?? 0}
	>
		{children}
	</Grid>
)

interface IPropsItem {
	children: React.ReactNode
	sm?: number
	md?: number
	lg?: number
	sx?: React.CSSProperties
}

export const GridItem = ({
	children,
	sm = 12,
	md = 6,
	lg = 4,
	sx = {},
}: IPropsItem) => (
	<Grid item xs={12} sm={sm} md={md} lg={lg} sx={sx}>
		{children}
	</Grid>
)
