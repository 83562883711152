import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const AuthRoutes = [
	{
		path: urlRoutes.auth.login.url,
		element: lazy(() => import('../../../pages/auth/login')),
	},
	{
		path: urlRoutes.auth.recover.url,
		element: lazy(() => import('../../../pages/auth/recover')),
	},
	{
		path: urlRoutes.auth.reset.url,
		element: lazy(() => import('../../../pages/auth/reset')),
	},
	{
		path: urlRoutes.auth.otpCode.url,
		element: lazy(() => import('../../../pages/auth/otpCode')),
	},
]
