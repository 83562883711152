import { lazy } from 'react'

import { urlRoutes } from '../../config/urlRoutes/urlRoutes'

export const ProfileRoutes = [
	{
		nameUrl: 'Mi perfil',
		path: urlRoutes.profile.url,
		element: lazy(() => import('../../../pages/profile')),
		rols: ['Admin', 'User', '*'],
	},
]
