import { createSlice } from '@reduxjs/toolkit'

export interface ILoggedUser {
	firstLogin: boolean
	fullName: string
	userType: string
	username: string
}

export interface IUserLocalStorage {
	accessExpiresIn: string
	accessToken: string
	logged: boolean
	loggedUser: ILoggedUser
	refreshExpiresIn: string
	refreshToken: string
}

const user: IUserLocalStorage = JSON.parse(
	localStorage.getItem('loggedUser') || '{}'
)

export const loggedUserSlice = createSlice({
	name: 'loggedUser',
	initialState: {
		logged: user?.logged,
		loggedUser: user?.loggedUser,
		accessToken: user?.accessToken,
		refreshToken: user?.refreshToken,
		accessExpiresIn: user?.accessExpiresIn,
		refreshExpiresIn: user?.refreshExpiresIn,
		isOpenSelect: false,
	},
	reducers: {
		setLoggedUser: (state, action) => {
			state.logged = action.payload.logged
			state.loggedUser = action.payload.loggedUser
			state.accessToken = action.payload.accessToken
			state.refreshToken = action.payload.refreshToken
			state.accessExpiresIn = action.payload.accessExpiresIn
			state.refreshExpiresIn = action.payload.refreshExpiresIn
		},
		setIsOpenSelect: (state, action) => {
			state.isOpenSelect = action.payload
		},
	},
})

export const getLoggedUser = (state: any) => state.loggedUser

export const { setLoggedUser, setIsOpenSelect } = loggedUserSlice.actions

export default loggedUserSlice.reducer
