import React, { MouseEvent, useState } from 'react'
import {
	Avatar,
	Box,
	Button,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material'
import Logout from '@mui/icons-material/Logout'
import { useSelector } from 'react-redux'

import { getLoggedUser } from '../../redux/slices/loggedUserSlice'

interface IAccountMenuProps {
	handleLogout: () => void
}

export default function AccountMenu({ handleLogout }: IAccountMenuProps) {
	const userLogged = useSelector(getLoggedUser)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(open ? null : event.currentTarget)
	}

	const handleClose = (e: any) => {
		setAnchorEl(null)
	}

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title="Configuraciones de la cuenta">
					<Button
						onClick={handleClick}
						size="small"
						sx={{ ml: 2 }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
						<Avatar sx={{ width: 32, height: 32 }}>
							{userLogged?.loggedUser?.fullName?.substring(0, 1)}
						</Avatar>
						<Typography
							color="white"
							fontSize={12}
							fontWeight="bold"
							align="left"
							ml={1}
						>
							{userLogged?.loggedUser?.fullName}
						</Typography>
					</Button>
				</Tooltip>
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={(e) => handleClose(e)}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Cerrar sesión
				</MenuItem>
			</Menu>
		</>
	)
}
