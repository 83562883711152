import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { urlRoutes } from './config/urlRoutes/urlRoutes'

interface IProps {
	isLoggedIn: boolean
}

const PublicRoutes = ({ isLoggedIn }: IProps) => {
	return !isLoggedIn ? <Outlet /> : <Navigate to={urlRoutes.home.url} />
}

export default PublicRoutes