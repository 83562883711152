import axios, { AxiosRequestConfig } from 'axios'

import {
	ApiResponse,
	AsyncApiResponse
} from '../../../domain/models/apiResponse'
import store from '../../../presentation/redux/store'
import { RecaptchaType } from '../../../presentation/utils/recaptcha'
import { urlRoutes } from '../../../presentation/routes/config/urlRoutes/urlRoutes'

type RequestProps = {
	get?: GetRequest
	post?: PostRequest
	put?: PutRequest
	patch?: any
	delete?: any
}

type PutRequest = {
	path: string
	body: any
}

type PostRequest = {
	path: string
	body: any
}

type GetRequest = {
	path: string
	query?: any
}

const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_URL_BASE
})

export function makeResponse<T>(response: any): ApiResponse<T> {
	return {
		data:
			response.data === ''
				? null
				: response.data != null && typeof response.data != 'undefined'
					? response.data
					: response
	}
}

export function makeErrorResponse<T>(error: any): ApiResponse<T> {
	if (error.response.status === 401) {
		window.location.replace(urlRoutes.auth.login.urlTo)
		localStorage.clear()
	}

	return {
		error: {
			status: error.response.status ?? 500,
			statusText: error.response.statusText ?? '',
			content: error.response.data ?? null
		}
	}
}

export interface IHeaders {
	key: string
	value: string
}

export const sendRequest = async <T>(
	props: RequestProps,
	recaptchaInfo?: RecaptchaType,
	auxHeaders?: IHeaders[]
): Promise<AsyncApiResponse<T>> => {
	let response = null

	const headers: any = {
		'Content-Type': 'application/json',
		'X-API-KEY': '31d46724-202e-48eb-b26b-4d7e8a16c95a',
		Locale: 'es_EC'
	}

	const accessToken = store.getState().loggedUser.accessToken

	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`
	}

	if (auxHeaders) {
		auxHeaders.forEach((header) => {
			headers[header.key] = header.value
		})
	}

	if (recaptchaInfo) {
		headers.RecaptchaAction = recaptchaInfo.recaptchaAction
		headers.RecaptchaToken = recaptchaInfo.recaptchaToken
	}

	const config: AxiosRequestConfig = {
		headers
	}

	try {
		if (props.get)
			response = await axiosInstance.get(props.get.path as string, config)
		if (props.post) {
			const responseCsrf = await axiosInstance.get(
				`${process.env.REACT_APP_URL_BASE}/csrf`,
				config
			)
			if (responseCsrf.data && config.headers) {
				config.headers[responseCsrf.data?.headerName] = responseCsrf.data?.token
			}
			response = await axiosInstance.post(
				props.post.path as string,
				props.post.body,
				config
			)
		}
	} catch (e) {
		throw makeErrorResponse<T>(e)
	}
	return makeResponse<T>(response)
}