import { ReadMemberUserQuery } from '../../application/queries/memberUser/readMemberUserQuery'
import { ReadMemberUserValidateSubscriptionByEmailQuery } from '../../application/queries/memberUser/readMemberUserValidateSubscriptionByEmailQuery'
import { UpdateMemberUserCommand } from '../../application/commands/memberUser/updateMemberUserCommand'
import MemberUserRepository from '../repositories/memberUserRepository'
import {
	AuthLoginUserRequest, AuthOtpCodeUserRequest,
	ResetPasswordCommandRequest, SetClassificationMemberUserCommandRequest,
	UpdateMemberUserCommandRequest
} from '../../domain/entities/memberUser'
import { LoginLoggedUserCommand } from '../../application/commands/memberUser/loginLoggedUserCommand'
import { RefreshTokenUserCommand } from '../../application/commands/memberUser/refreshTokenUserCommand'
import { RecoverPassLoggedUserCommand } from '../../application/commands/memberUser/recoverPassLoggedUserCommand'
import { ResetPassLoggedUserCommand } from '../../application/commands/memberUser/resetPassLoggedUserCommand'
import { LogoutLoggedUserCommand } from '../../application/commands/memberUser/logoutLoggedUserCommand'
import {
	SetClassificationMemberUserCommand
} from '../../application/commands/memberUser/setClassificationMemberUserCommand'
import { OtpCodeUserCommand } from '../../application/commands/memberUser/otpCodeUserCommand'

const memberUserRepository = new MemberUserRepository()

export class MemberUserController {
	static async getById(id: string) {
		return await ReadMemberUserQuery(memberUserRepository, id)
	}

	static async update(data: UpdateMemberUserCommandRequest) {
		return await UpdateMemberUserCommand(memberUserRepository, data)
	}

	static async otpCode(data: AuthOtpCodeUserRequest) {
		return await OtpCodeUserCommand(memberUserRepository, data)
	}

	static async login(data: AuthLoginUserRequest) {
		return await LoginLoggedUserCommand(memberUserRepository, data)
	}

	static async logout(refreshToken: string) {
		return await LogoutLoggedUserCommand(memberUserRepository, refreshToken)
	}

	static async refreshToken(refreshToken: string) {
		return await RefreshTokenUserCommand(memberUserRepository, refreshToken)
	}

	static async recoverPassword(email: string) {
		return await RecoverPassLoggedUserCommand(memberUserRepository, email)
	}

	static async resetPassword(data: ResetPasswordCommandRequest) {
		return await ResetPassLoggedUserCommand(memberUserRepository, data)
	}

	static async validateSubscriptionByEmail(email: string) {
		return await ReadMemberUserValidateSubscriptionByEmailQuery(
			memberUserRepository,
			email
		)
	}

	static async setClassification(data: SetClassificationMemberUserCommandRequest) {
		return await SetClassificationMemberUserCommand(memberUserRepository, data)
	}
}
